import React from 'react'
import Link from 'gatsby-link'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import AOS from 'aos';
import 'aos/dist/aos.css';
import {web_design, google_advertising, facebook_advertising, seo_optimization, graphic_design, web_design_text, google_advertising_text, facebook_advertising_text,
     seo_optimization_text, graphic_design_text, portfolio, consulting, consulting_text, see_more} from '../strings'

class TabsAbout extends React.Component{
    componentDidMount(){
        AOS.init({
          duration : 500
        })
      }
      
render(){
    var prefix_string_page = "";
    var web_design_tag = "Learn more about our web site design and programming of websites, e-commerces & apps";
    var google_advertising_tag = "WELL advertisment – we offer you professional advertising on Google – GOOGLE ADS";
    var facebook_advertising_tag = "WELL offers you professional advertising on social media – Facebook, Instagram, LinkedIn, XING, Twitter & Co.";
    var seo_optimization_tag = "WELL experienced in professional Search Engine Optimisation - SEO";
    var graphic_design_tag = "WELL experienced in graphic design – product design, brochures, flyers, visit cards and, and, and ....";
    var link_web_design = "/web-design";
    var link_graphic_design = "/graphic-design";
    var link_digital_marketing = "/digital-marketing";
    var link_consulting = "/consulting"; 
    var web_design_button = "See more Web Design projects";
    var google_advertising_button = "See more Google ADvertising projects";
    var facebook_advertising_button = "See more Social Media projects";
    var seo_optimization_button = "See more SEO Optimization projects";
    var graphic_design_button = "See more Graphic Design projects";
    
    if(this.props.prefix_string_page === "bs"){
        prefix_string_page = "bs";
        web_design_tag = "Saznaj više o našim projektima web dizajniranja, programiranja web stranica i aplikacija";
        google_advertising_tag = "WELL Vam nudi profesionalno oglašavanje na Google-u – GOOGLE REKLAME";
        facebook_advertising_tag = "WELL Vam nudi profesionalno oglašavanje na društvenim mrežama Facebook, Instagram, LinkedIn, XING, Twitter & Co";
        seo_optimization_tag = "WELL – sa bogatim iskustvom za optimiranje položaja Vaše web stranica na tražilicama - SEO";
        graphic_design_tag = "WELL – sa bogatim iskustvom u grafičkom dizajnu – dizajn proizvoda, kataloga, flajera, vizitki i još mnogo toga";
        link_web_design = "/web-dizajn";
        link_graphic_design = "/graficki-dizajn";
        link_digital_marketing = "/digitalni-marketing";
        link_consulting = "/konzalting"; 
        web_design_button = "Pogledaj više projekata iz kategorije Web Dizajn";
        google_advertising_button = "Pogledaj više projekata iz kategorije Google Oglašavanje";
        facebook_advertising_button = "Pogledaj više projekata iz kategorije Društvene Mreže";
        seo_optimization_button = "Pogledaj više projekata iz kategorije SEO Optimizacija";
        graphic_design_button = "Pogledaj više projekata iz kategorije Grafički Dizajn"; 
    }
    return(
    <Tabs className="columns">
        <div data-aos='fade-zoom-in' className="column is-half left">
            <TabList>
                <Tab><span className="web" title={web_design_tag}>{web_design}</span></Tab>
                <Tab><span className="google" title={google_advertising_tag}>{google_advertising}</span></Tab>
                <Tab><span className="facebook" title={facebook_advertising_tag}>{facebook_advertising}</span></Tab>
                <Tab><span className="seo" title={seo_optimization_tag}>{seo_optimization}</span></Tab>
                <Tab><span className="graphic" title={graphic_design_tag}>{graphic_design}</span></Tab>
                <Tab><span className="consulting">{consulting}</span></Tab>
            </TabList>
        </div>

        <div data-aos='fade-zoom-in' className="column is-half right">
        <TabPanel className="panel web">
            <div className="inner">
                <h3>{web_design}</h3>
                <p>{web_design_text}</p>
                <Link to={prefix_string_page + "/portfolio" + link_web_design} className="button black" title={web_design_button}>{portfolio}<span class="icon arrow"></span></Link>
            </div>
        </TabPanel>
        <TabPanel className="panel google">
            <div className="inner">
                <h3>{google_advertising}</h3>
                <p>{google_advertising_text}</p>
                <Link to={prefix_string_page + "/portfolio" + link_digital_marketing} className="button black" title={google_advertising_button}>{portfolio}<span class="icon arrow"></span></Link>
            </div>
        </TabPanel>
        <TabPanel className="panel facebook">
            <div className="inner">
                <h3>{facebook_advertising}</h3>
                <p>{facebook_advertising_text}</p>
                <Link to={prefix_string_page + "/portfolio" + link_digital_marketing} className="button black" title={facebook_advertising_button}>{portfolio}<span class="icon arrow"></span></Link>
            </div>
        </TabPanel>
        <TabPanel className="panel seo">
            <div className="inner">
                <h3>{seo_optimization}</h3>
                <p>{seo_optimization_text}</p>
                <Link to={prefix_string_page + "/portfolio" + link_digital_marketing} className="button black" title={seo_optimization_button}>{portfolio}<span class="icon arrow"></span></Link>
            </div>
        </TabPanel>
        <TabPanel className="panel graphic">
            <div className="inner">
                <h3>{graphic_design}</h3>
                <p>{graphic_design_text}</p>
                <Link to={prefix_string_page + "/portfolio" + link_graphic_design} className="button black" title={graphic_design_button}>{portfolio}<span class="icon arrow"></span></Link>
            </div>
        </TabPanel>
        <TabPanel className="panel consulting">
            <div className="inner">
                <h3>{consulting}</h3>
                <p>{consulting_text}</p>
                <Link to={prefix_string_page + link_consulting} className="button black" title={graphic_design_button}>{see_more}<span class="icon arrow"></span></Link>
            </div>
        </TabPanel>
        </div>
    </Tabs>
    )
}
}

//CONTENT OF "OUR SERVICES" SECTION//
export default TabsAbout


