import React from 'react'
import Columns from './columns'
import 'aos/dist/aos.css';
import {why_well} from '../strings'
const Brands = ({ prefix_string_page }) => (
    
    <section id="s_brands" className="section default">
    <div className="brands-back"></div>
        <div className="container">
         <div data-aos='fade-zoom-in' className="title-box">
                <div className="first">
                    <h2 className="title"> <span className="section-number">02</span>{why_well}</h2>
                </div>
            </div>
            <Columns  prefix_string_page={prefix_string_page}/>
        </div>
    </section>
)

//TITLE OF "WHY WELL" SECTION//
export default Brands
