import React from 'react'
import Swiper from 'react-id-swiper';
import 'aos/dist/aos.css';
import therese_corriente_img from '../../images/Therese-Corriente-Jevricorr-Web-Design-E-Commerce-Social-Media-Consulting-Branding-Graphic-Design.png'
import nataliya_schweda_img from '../../images/Nataliya-Schweda-Photoschweda-SEO-Optimization-Consulting.png'
import michael_zechner_img from '../../images/Michael-Zechner-Lab-Consulting-Consulting-Graphic-Design-Web-Maintaining.png'
import {therese_corriente, nataliya_schweda, michael_zechner} from '../../components/strings'
class TestimonialClients extends React.Component{
    render(){
        const params = {
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
              },
              loop: true         
    }
    var teresa_tag = "Our client Therese Corriente, Company „Jevricorr LLC“, projects: Consulting, Branding, E-commerce, Social Media Advertising (Facebook, Instagram), Graphic Design.";
    var nataliya_tag = "Our client Nataliya Schweda, Company Photoschweda, CEO, projects: SEO Optimization, Consulting, Web Maintaining";
    var michael_tag = "Our client Michael Zechner, Company Lab Consulting, Sales Manager, projects: Consulting, Web Maintaining, Graphic Design";
    if(this.props.prefix_string_page === "bs"){
        teresa_tag = "Naš klijent Therese Corriente, kompanija „Jevricorr LLC“,  projekti: konzalting, brendiranje, online trgovina, oglašavanje na društvenim mrežama (Instagram i Facebook), grafički dizajn.";
        nataliya_tag = "Naš klijent Nataliya Schweda, kompanija Photoschweda, CEO, projekti: SEO Optimizacija, Konzalting, Web održavanje";
        michael_tag = "Naš klijent Michael Zechner, Kompanija Lab Consulting, projekti: Menadžer Prodaje, projekti: Konzalting, Web Održavanje, Grafički Dizajn";
    }

    return(
    <section id="s_testimonials_clients" className="section default">
            <div className="overlay_quotes"></div>        
            <div data-aos='fade-zoom-in' className="container">
                <Swiper {...params}>
                    <div className="single-client">
                        <div className="name-box">
                        <img src={therese_corriente_img} alt={teresa_tag} title={teresa_tag} />
                        <div className="right">
                            <h3 className="name">Therese Corriente</h3>
                            <h3 className="company">JEVRICORR, LLC</h3>
                        </div>
                        </div>

                        <div className="message-box">
                            <p className="text">{therese_corriente}</p>
                        </div>
                    </div>

                    <div className="single-client">
                        <div className="name-box">
                        <img src={nataliya_schweda_img} alt={nataliya_tag} title={nataliya_tag} />
                        <div className="right">
                        <h3 className="name">Nataliya Schweda</h3>
                        <h3 className="company">PHOTOSCHWEDA, CEO</h3>
                        </div>
                        </div>

                        <div className="message-box">
                            <p className="text">{nataliya_schweda}</p>
                        </div>
                    </div>

                    <div className="single-client">
                        <div className="name-box">
                        <img src={michael_zechner_img} alt={michael_tag} title={michael_tag} />
                        <div className="right">
                        <h3 className="name">Mag. Michael Zechner</h3>
                        <h3 className="company">LAB CONSULTING, Sales Manager</h3>
                        </div>
                        </div>

                        <div className="message-box">
                            <p className="text">{michael_zechner}</p>
                        </div>
                    </div>
                    
                </Swiper>
                <div className="arrows-box columns">
                  {/* <span className="arrow arrow-left column "><FontAwesomeIcon icon={['fas', 'long-arrow-alt-left']} aria-hidden="true" /></span> */}
               {/*}     <h6 className="number column">01/<span>05</span></h6> */}
                  {/*    <span className="arrow arrow-right column "><FontAwesomeIcon icon={['fas', 'long-arrow-alt-right']} aria-hidden="true" /></span>*/}
                </div>
            </div>
    </section>
    )
    }
}
//CLIENTS TESTIMONIALS//
export default TestimonialClients
