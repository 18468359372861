import React from 'react'
import Swiper from 'react-id-swiper';
import coche_box from "../../images/partners/coche_box.png"
import akern from "../../images/partners/akern.png"
import swiss from "../../images/partners/swiss_jewellery.png"
import { Link } from 'gatsby'
import keka from "../../images/partners/keka.png"
import kupitehniku from "../../images/partners/kupitehniku.png"
import labconsulting from "../../images/partners/labconsulting.png"
import modroizeleno from "../../images/partners/modroizeleno.png"
import photoschweda from "../../images/partners/photoschweda.png"
import vipcars from "../../images/partners/vipcars.png"
import sarayresidence from "../../images/partners/sarayresidence.png"
import bellona from "../../images/partners/bellona.png"
import { full_List_references } from '../strings';
import luxor from "../../images/partners/luxor.png"
import 'aos/dist/aos.css';

class PartnersSlider extends React.Component {
    render() {
        const params = {
            slidesPerView: 8,
            spaceBetween: 0,
            loop: true,
            centeredSlides: true,
            autoplay: {
              delay: 2500,
              disableOnInteraction: false
            },   
          }

      var prefix_string_page = "";   
      var link_references = "/references"; 
      var akern_tag = "A-KERN Steuerberatung, Website Design, Seo Optimization, CMS, Graphic Design, Email Signatures, Consulting";
      var coche_box_tag = "Coche Box, Website Design, E-Commerce, Brending, SEO Optimization, Graphic Design, Social Media";
      var swiss_tag = "Swiss Jevellery, Website Design, Programming, Graphic Design, CRM";
      var keka_tag = "Keka, Website Design, Programming, Social Media, SEO Optimization";
      var kupitehniku_tag = "Kupi Tehniku, Web Maintaining, Email Services, Consulting";
      var labconsulting_tag = "Lab Consulting, Web Maintaining, Graphic Design";
      var modroizeleno_tag = "Modro i Zeleno, Web Design, Blog, SEO Optimization, Graphic Design, Social Media, Brending";
      var photoschweda_tag = "Photoschweda, Web Maintaining, Consulting";
      var vipcars_tag = "Vip Cars Luxury Cars For Rent, Brending, Web Design, SEO Optimization, Social Media";
      var sarayresidence_tag = "Saray Residence Real Estate, Web Design, Graphic Design, SEO Optimization, Consulting, Social Media";
      var bellona_tag = "Bellona Furniture, Web Design, Online Shop, Social Media, Google Adwords";
      var luxor_tag = "Luxor, Web Design, Programming";
      var references_tag = "See all references in areas  consulting, branding, digital marketing, programming and web & graphic design projects";
      if(this.props.prefix_string_page === "bs"){
        prefix_string_page = "bs";
        link_references = "/reference";
        akern_tag = "A-KERN Steuerberatung, Website Dizajn, SEO Optimizacija, CMS, Grafički Dizajn, Email Signature, Konzalting";
        coche_box_tag = "Coche Box, Website Dizajn, Online Shop, Brending, SEO Optimizacija, Grafički Dizajn, Društvene mreže";
        swiss_tag = "Swiss Jevellery, Website Dizajn, Programiranje, Grafički Dizajn, CRM";
        keka_tag = "Keka, Website Dizajn, Programiranje, Društvene mreže, SEO Optimizacija";
        kupitehniku_tag = "Kupi Tehniku, Održavanje Stranice, Email Usluge, Konzalting";
        labconsulting_tag = "Lab Consulting, Održavanje Stranice, Grafički Dizajn";
        modroizeleno_tag = "Modro i Zeleno, Website Dizajn, Blog, SEO Optimizacija, Grafički Dizajn, Društvene mreže, Brendiranje";
        photoschweda_tag = "Photoschweda, Održavanje Stranice, Konzalting";
        vipcars_tag = "Vip Cars Luxury Cars For Rent, Brendiranje, Website Dizajn, SEO Optimizacija, Društvene mreže";
        sarayresidence_tag = "Saray Residence Real Estate, Website Dizajn, Grafički Dizajn, SEO Optimizacija, Konzalting, Društvene mreže";
        bellona_tag = "Bellona Furniture, Website Dizajn, Online Shop, Društvene mreže, Google Adwords";
        luxor_tag = "Luxor, Website Dizajn, Programiranje";
        references_tag = "Pogledaj više referenci iz oblasti , konzalting, brendiranje, digitalni marketing, programiranje, web i grafički dizajn";
      }
   
      return(
          <section id="s_testimonials_partners" className="section hero">
            <div className="hero-body">
                <div data-aos='fade-zoom-in' className="container">
                    <Swiper {...params}>
                    <div className="single-partner"><a href="https://akern.at/" target="_blank"><img src={akern} alt={akern_tag} title={akern_tag} /></a></div>
                    <div className="single-partner"><a href="http://cochebox.com/" target="_blank"><img src={coche_box} alt={coche_box_tag} title={coche_box_tag} /></a></div>
                    <div className="single-partner"><a href="http://swissjewellery.ba/en/home/" target="_blank"><img src={swiss} alt={swiss_tag} title={swiss_tag} /></a></div>
                    <div className="single-partner"><a href="https://kupitehniku.ba/" target="_blank"><img src={kupitehniku} alt={kupitehniku_tag} title={kupitehniku_tag} /></a></div>
                    <div className="single-partner"><a href="https://www.labconsulting.at/" target="_blank"><img src={labconsulting} alt={labconsulting_tag} title={labconsulting_tag} /></a></div>
                    <div className="single-partner"><a href="https://modroizeleno.com/" target="_blank"><img src={modroizeleno} alt={modroizeleno_tag} title={modroizeleno_tag} /></a></div>
                    <div className="single-partner"><a href="http://photoschweda.at/" target="_blank"><img src={photoschweda} alt={photoschweda_tag} title={photoschweda_tag} /></a></div>
                    <div className="single-partner"><a href="http://keka.ba/" target="_blank"><img src={keka} alt={keka_tag} title={keka_tag} /></a></div>
                    <div className="single-partner"><a href="https://vipcars.ba/" target="_blank"><img src={vipcars} alt={vipcars_tag} title={vipcars_tag} /></a></div>
                    <div className="single-partner"><a href="http://sarayresidence.ba/" target="_blank"><img src={sarayresidence} alt={sarayresidence_tag} title={sarayresidence_tag} /></a></div> 
                    <div className="single-partner"><a href="http://bellona.ba/" target="_blank"><img src={bellona} alt={bellona_tag} title={bellona_tag} /></a></div>   
                    <div className="single-partner"><a href="http://luxor.ba/" target="_blank"><img src={luxor} alt={luxor_tag} title={luxor_tag} /></a></div>     
                    </Swiper>

                     <div className="button-area"><Link to={prefix_string_page + link_references} className="button black center" title={references_tag}>{full_List_references}<span class="icon arrow"></span></Link></div>

                </div>
            </div>
        </section>
      )
    }
  }
   
//PARTNERS SLIDER FOR "TESTIMONIALS" SECTION//
  export default PartnersSlider
