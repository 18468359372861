import React from 'react'
import {our_clients} from '../strings'
import 'aos/dist/aos.css';
const TestimonialTitle = () => (

    <section id="s_testimonials_title" className="section default">
            <div className="container">
                <div data-aos='fade-zoom-in' className="title-box">
                    <div className="first">
                        <h2 className="title"><span className="section-number">04</span>{our_clients}</h2>
                    </div>
                </div>
            </div>
    </section>
)

//TITLE FOR "TESTIMONIALS" SECTION//
export default TestimonialTitle
