import React from 'react'
import Content from './content'
const Intro = ({ prefix_string_page }) => (
      <section id="s_intro" className="hero is-fullheight">
            <span className="animation-box heart"></span>
            <span className="animation-box like"></span>
            <span className="animation-box at"></span>
            <span className="animation-box media"></span>
            <span className="animation-box bracket-left"></span>
            <span className="animation-box bracket-right"></span>
            <span className="animation-box message"></span>
            <span className="animation-box seo"></span>

            <div className="hero-head"></div>
                <div data-aos='fade-zoom-in' className="hero-body">
                    <div className="overlay_image"></div>
                    <div className="overlay_color"></div>
                    <div className="container">        
                        <Content prefix_string_page = {prefix_string_page}/>
                    </div>
                </div>
            <div className="hero-foot">
            </div>
        </section>
)

//BACKGROUND CONTENT FOR "INTRO" SECTION//
export default Intro
