import React from 'react'
import Link from 'gatsby-link'
import 'aos/dist/aos.css';
import {promote_text, contact_us} from '../strings'

class Promoting extends React.Component {
    render(){
        var prefix_string_page = "";
        var link_contact = "/contact";
        var promoting_tag = "Contact us and find out more about our offers: Web & Apps Programming, digital marketing services (SEO, Google Ads & social media advertising and graphic & web design";
        if(this.props.prefix_string_page === "bs"){
            prefix_string_page  = "bs";
            link_contact = "/kontakt";
            promoting_tag = "Kontaktirajte nas i saznajte više o našim ponudama: Programiranje weba i aplikacija, usluge digitalnog marketinga (SEO, Google oglasi i oglašavanje društvenih medija) te grafički i web dizajn";
        }
        return(
    <section id="s_promoting" className="section default">
            <div className="container">
                <div className="columns content">
                    <div data-aos='fade-zoom-in' className="column is-7 left">
                        <h3 className="title">{promote_text}</h3>
                        {/*<p className="text">Well Business Promotion može biti marketing odjel u vašoj firmi.</p>*/}
                    </div>

                    <div data-aos='fade-zoom-in' className="column is-5 right">
                        <Link to={prefix_string_page + link_contact} className="button black is-icon-back is-arrow-right" title={promoting_tag}>{contact_us}<span class="icon arrow"></span></Link>
                    </div> 
                </div>
            </div>
    </section>
    )
    }
}
//CONTENT FOR "PROMOTING" SECTION
export default Promoting
