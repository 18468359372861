import React from 'react'
import TestimonialsTitle from './title'
import TestimonialsClients from './clients'
import PartnersSlider from '../Partners/partners'
import 'aos/dist/aos.css';

const Testimonials = ({prefix_string_page}) => (
    <div>
        <TestimonialsTitle/>
        <TestimonialsClients prefix_string_page={prefix_string_page}/>
    </div>
)

//TESTIMONIALS MAIN COMPONENT//
export default Testimonials
