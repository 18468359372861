import React from 'react'
import 'aos/dist/aos.css';
import {experienced_team, complete_offer, attractive_prices,
    proffesional_advice, experienced_team_text, complete_offer_text, attractive_prices_text, proffesiona_advice_text, proffesional_advice_text} from '../strings'
class Columns extends React.Component{
    render(){
        var team_tag = "WELL‘S talented and experienced individuals are at your disposal for all digital marketing, web programming and design services.";
        var consulting_tag = "Arrange an appointment for free consulting";
        var offer_tag = "Complete offer – programming of web sites and apps, graphic design, digital marketing (SEO, Google, Facebook, Instagram, Linkedin ... Advertising.";
        var prices_tag = "Attractive prices and high quality offer for our services of digital marketing and programming.";
        if(this.props.prefix_string_page === "bs"){
            team_tag = "Naš tim stručnjaka Vam za sve usluge digitalnog marketinga, web programiranja i dizajna stoji na raspolaganju.";
            consulting_tag = "Dogovori termin za besplatno savjetovanje (konzalting).";
            offer_tag = "Kompletna ponuda- programiranje web stranica i aplikacija, grafički dizajn, SEO, reklamiranje preko Google i društvenih mreža (Instagram, Facebook, LinkedIn ...) ";
            prices_tag = "Atraktivne cijene za visokokvalitetne usluge digitalnog marketinga i programiranja.";
        }
        return(

        <div className="columns">
         <div data-aos='fade-zoom-in' className="column team">
         <div>
            <div className="hover_overlay"></div>
                <span className="icon" title={team_tag}></span>
                <h3 className="title">{experienced_team}</h3>
                <p className="text">{experienced_team_text}</p>
                </div>
            </div>

             <div data-aos='fade-zoom-in' className="column consulting">
             <div>
            <div className="hover_overlay"></div>
                <span className="icon" title={consulting_tag}></span>
                <h3 className="title">{proffesional_advice}</h3>
                <p className="text">{proffesional_advice_text}</p>
                </div>
            </div>

                <div data-aos='fade-zoom-in' className="column offer">
                <div>
            <div className="hover_overlay"></div>
                <span className="icon" title={offer_tag}></span>
                <h3 className="title">{complete_offer}</h3>
                <p className="text">{complete_offer_text}</p>
                </div>
            </div>
            
            <div data-aos='fade-zoom-in' className="column price">
            <div>
            <div className="hover_overlay"></div>
                <span className="icon" title={prices_tag}></span>
                <h3 className="title">{attractive_prices}</h3>
                <p className="text">{attractive_prices_text}</p>
            </div>
            </div>  
        </div>
    )
}
}

//CONTENT OF "WHY WELL" SECTION//
export default Columns
