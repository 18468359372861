import React from 'react'
import TabsAbout from './tabs'
import {our_services} from '../strings'
import 'aos/dist/aos.css';

const About = ({ prefix_string_page }) => (
        
        <div className="big-back">
        <div className="container">
            <div data-aos='fade-zoom-in' className="title-box">
                <div className="first">    
                    <h2 className="title"><span className="section-number">01</span>{our_services}</h2>
                </div>
            </div>

            <TabsAbout prefix_string_page={prefix_string_page}/>
        </div>
        </div>
)

//TITLE OF "OUR SERVICES" SECTION//
export default About
