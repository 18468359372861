import React from 'react'
import Typist from 'react-typist'
import TypistLoop from 'react-typist-loop'
import 'aos/dist/aos.css';
import AnchorLink from 'react-anchor-link-smooth-scroll'
import {agency_for, web_design_intro, google_advertising_intro, facebook_advertising_intro, seo_optimization_intro, graphic_design_intro, intro_text} from '../strings'

class Content extends React.Component{
  render(){
        var prefix_string_page = "";
        var link_our_services = "#our-services";
        if(this.props.prefix_string_page === "bs"){
          prefix_string_page = "bs";
          link_our_services = "#nase-usluge";
        }
    return(
    <div className="content">
    <div data-aos='fade-zoom-in'><h6 className="is-margin-bottom-0">MI SMO</h6></div>
    <div data-aos='fade-zoom-in'><h1> <br /> {agency_for}&nbsp;
    <TypistLoop interval={1500} >
    {[
      `${web_design_intro}`,
      `${google_advertising_intro}`,
      `${facebook_advertising_intro}`,
      `${seo_optimization_intro}`,
      `${graphic_design_intro}`,
    ].map(text => <Typist key={text} startDelay={250} delay={500} cursor={{
        show: true,
        blink: true,
        element: '|',
        hideWhenDone: false,
        hideWhenDoneDelay: 1000,
      }}>{text}</Typist>)}
  </TypistLoop>
    </h1></div>
     <div data-aos='fade-zoom-in'><h4>{intro_text}</h4></div>
    <div className="button-area">
      <AnchorLink offset='0' href={link_our_services} className="link-more"></AnchorLink>
    </div>
    </div>
)
    }
  }
  
//CONTENT FOR "INTRO" SECTION//
export default Content

